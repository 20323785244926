import { includes } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { AllowedUsersDialog } from '../../configuration-pages/course-configurations/components/learning-management-system/allowed-users-dialog/allowed-users-dialog.component';
import { allowedOrgsTypes, MessageButton, Messages, userTypes } from 'src/app/shared/interfaces/Message.interface';

declare let cloudinary: any;

@Component({
  selector: 'app-messages-modal',
  templateUrl: './messages-modal.component.html',
  styleUrls: ['./messages-modal.component.scss'],
})
export class MessagesModalComponent implements OnInit {
  @Output() newMessage = new EventEmitter<any>();

  messageForm: Messages = {
    text: '',
    image: '',
    allowedUsers: [],
    mediaType: 'image',
    video: '',
    isRead: false,
    createdAt: new Date(),
    organizationId: '',
  };

  allowedOrgs: string[] = [];
  usersRead: any[] = [];
  safeMessageVideoUrl: SafeResourceUrl;
  selectedHowToSend: string;

  readonly userTypes = userTypes;
  readonly allowedOrgsTypes = allowedOrgsTypes;
  readonly cloudinaryConfig = {
    cloud_name: environment.CLOUDINARY_CLOUD_NAME,
    upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
    secure: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Messages,
    private dialogRef: MatDialogRef<MessagesModalComponent>,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private restService: RestAPIService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.data?.data) {
      await this.initializeData();
    }
  }

  private async initializeData(): Promise<void> {
    const { data } = this.data;
    this.messageForm = {
      ...this.messageForm,
      text: data.text,
      allowedUsers: data.allowedUsers,
      mediaType: data.mediaType || 'image',
      image: data.mediaType === 'image' ? data.image : '',
      video: data.mediaType === 'video' ? data.video : '',
    };

    if (this.messageForm.video) {
      this.updateSafeUrl();
    }

    this.usersRead = await this.getReadMessages(data.id);
  }

  private async getReadMessages(messageId: string): Promise<any[]> {
    try {
      const users = await this.restService.get(`message/read/id/${messageId}`);
      const userIds = users.map((user) => user.accountId);
      const organizations = await this.restService.get('organization/all');

      return organizations.filter((org) => userIds.includes(org.id));
    } catch (error) {
      this.showError('Error fetching users who read the message');
      return [];
    }
  }

  async save(): Promise<void> {
    const { type } = this.data as MessageButton;

    if (type === 'PUT') {
      await this.update();
    } else {
      await this.create();
    }
  }

  async create() {
    try {
      const createdMessage: Messages = await this.restService.post('message', this.messageForm);
      this.data = createdMessage;
      this.newMessage.emit(this.data);
      this.close();
    } catch (error) {
      this.showError('Could not save the message');
    }
  }

  async update() {
    const { data } = this.data;
    try {
      const updatedMessage = await this.restService.put(`message/id/${data.id}`, this.messageForm);
      this.data = updatedMessage;
      this.newMessage.emit(this.data);
      this.close();
    } catch (error) {
      this.showError('Could not update the message');
    }
  }

  public close() {
    this.dialogRef.close();
  }

  addMessageImage(): void {
    cloudinary.openUploadWidget(this.cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        this.messageForm.image = imageResponse.secure_url || imageResponse.url;
      } else if (error?.message !== 'User closed widget') {
        this.showError('Error uploading the image');
      }
    });
  }

  async openAllowedOrgsDialog(): Promise<void> {
    const dialogRef = this.dialog.open(AllowedUsersDialog, {
      width: '600px',
      height: '650px',
      disableClose: true,
      data: this.data,
      panelClass: 'modal-border',
    });

    dialogRef.afterClosed().subscribe(async (shouldSave: boolean) => {
      if (shouldSave) {
        await this.updateAllowedOrganizations(dialogRef.componentInstance.data.allowedOrganizations);
      }
    });
  }

  private async updateAllowedOrganizations(selectedOrgs: string[]): Promise<void> {
    try {
      const organizations = await this.restService.get('organization/all');
      this.allowedOrgs = organizations.filter((org) => selectedOrgs.includes(org.organization.id));
    } catch (error) {
      this.showError('Error updating allowed organizations');
    }
  }

  updateSafeUrl(): void {
    const videoId = this.extractVideoId(this.messageForm.video);
    this.safeMessageVideoUrl = videoId
      ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}`)
      : null;
  }

  private extractVideoId(url: string): string | null {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  private showError(message: string): void {
    this.snackBar.open(message, 'OK', { duration: 5000 });
  }

  getUserTypes(): string[] {
    return Object.keys(this.userTypes);
  }

  getAllowedOrgsTypes(): string[] {
    return Object.keys(this.allowedOrgsTypes);
  }

  checkUserType(user: string): boolean {
    return includes(this.messageForm.allowedUsers, user);
  }

  addAllowedUser(user: string): void {
    const index = this.messageForm.allowedUsers.indexOf(user);
    if (index !== -1) {
      this.messageForm.allowedUsers.splice(index, 1);
    } else {
      this.messageForm.allowedUsers.push(user);
    }
  }

  isAllowedOrgs(userType: string): boolean {
    return this.allowedOrgs.includes(userType);
  }

  async addAllowedOrgs(user: string): Promise<void> {
    if (this.allowedOrgs.includes(user)) {
      this.allowedOrgs = this.allowedOrgs.filter((u) => u !== user);
    } else {
      this.allowedOrgs = [user];
      if (user === 'manually') {
        this.selectedHowToSend = 'manually';
        await this.openAllowedOrgsDialog();
      }
    }
  }
}
