import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  faSquarePen,
  faTrash,
  IconDefinition,
  faXmark,
  faMagnifyingGlass,
  faPenToSquare,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ContainerTypes, defaultLanguage, Games, GraphTypes } from './utils/categories-utils';
import { get, includes } from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { NewCategoryDialog } from './new-categories-dialog/new-categories-dialog';
import { BonusPackDialog } from './bonus-pack-dialog/bonus-pack-dialog';
import { Category, CategoryDescription, Language, UnlockCondition } from '../../../interfaces/global-config.interfaces';

declare let cloudinary: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  constructor(
    private rest: RestAPIService,
    protected _snackBar: MatSnackBar,
    private confirm: ConfirmationService,
    protected dialog: MatDialog,
  ) {}

  public filteredCategories = [];
  public selectedCategory: Category;
  public readonly categoriesColums: string[] = ['name', 'maxSessions', 'actions'];
  public readonly conditionsColums: string[] = ['category', 'session', 'actions'];
  public readonly langDisplayedColumns: string[] = ['name', 'code', 'enabled'];
  public readonly gamesColums: string[] = ['games', 'enabled'];
  public readonly trash: IconDefinition = faTrash;
  public readonly update: IconDefinition = faSquarePen;
  public readonly remove: IconDefinition = faXmark;
  public readonly preview: IconDefinition = faMagnifyingGlass;
  public readonly edit: IconDefinition = faPenToSquare;
  public readonly question: IconDefinition = faQuestionCircle;

  public gamesList = Games;
  public graphsList = GraphTypes;
  public containerTypes = ContainerTypes;

  public categoryName: string;
  public categorySession = 0;

  public loadingCategories = true;
  public checked = true;
  public unchecked = false;

  public unlockConditionsDataSource: MatTableDataSource<UnlockCondition>;
  public gamesDataSource: MatTableDataSource<string>;
  public langDataSource: MatTableDataSource<Language>;

  @Output() reset = new EventEmitter();
  @Input() categories: Category[];
  @Input() languages: Language[];
  @Input() categoryToSelect: Category;

  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit() {
    this.gamesDataSource = new MatTableDataSource(Object.keys(Games));
    this.langDataSource = new MatTableDataSource(this.languages || []);

    if (this.categoryToSelect) {
      this.selectedCategory = this.categoryToSelect;
      this.buildUnlockConditionsTable(this.selectedCategory);
    }

    this.checkContainerType();
    this.checkCategoryOrder();
    this.checkCategoryDuration();
    this.addDescriptionsPerLanguage();

    this.gamesDataSource.sort = this.sort;
    this.loadingCategories = false;
  }

  public checkContainerType() {
    for (const category of this.categories) {
      if (!category.containerType) {
        category.containerType = ContainerTypes.COREPROGRAM;
      }
    }
  }

  public checkCategoryOrder() {
    for (const category of this.categories) {
      if (category.order === undefined) {
        category.order = 0;
      }
    }
  }

  public checkCategoryDuration() {
    for (const category of this.categories) {
      if (category.gameDuration === undefined) {
        category.gameDuration = 100;
      }
    }
  }

  public addDescriptionsPerLanguage() {
    this.categories = this.categories.map((category, index) => {
      if (!category.descriptionList) {
        category.descriptionList = [];
      }

      const descriptionList = category.descriptionList;

      // check if the related language still exist, if it doesnt remove it from the list
      for (const description of descriptionList) {
        const relatedLanguage = this.languages.find((l) => l.id === description.languageId);

        if (!relatedLanguage) {
          descriptionList.splice(index, 1);
        }
      }

      // check if the category have the description object for all the languages
      // if it doesnt, add it
      for (const language of this.languages) {
        const relatedDescription = descriptionList.find((d) => d.languageId === language.id);

        if (!relatedDescription) {
          const newDescription: CategoryDescription = {
            languageCode: language.languageCode,
            languageId: language.id,
            flagCode: language.flagCode,
            languageName: language.name,
            text: language.languageCode === defaultLanguage ? category.description : '',
          };

          descriptionList.push(newDescription);
        }
      }

      category.descriptionList = descriptionList;

      return category;
    });
  }

  public buildUnlockConditionsTable(selectedCategory: Category) {
    this.getFilteredCategoriesList();
    this.categoryName = get(this.filteredCategories[0], 'name', '');
    this.unlockConditionsDataSource = new MatTableDataSource(selectedCategory.unlockCondition);
  }

  public resetSession() {
    this.categorySession = 0;
  }

  public getCategoryMaxSession() {
    const selectedCategory = this.categories.find((c: Category) => c.name === this.categoryName);

    return selectedCategory ? selectedCategory.maxSessions : 0;
  }

  public addNewCondition() {
    const category = this.categories.find((c: Category) => c.name === this.categoryName);
    const session = this.categorySession;
    const unlockCondition = { name: category.name, session };
    this.selectedCategory.unlockCondition.push(unlockCondition);
    this.buildUnlockConditionsTable(this.selectedCategory);
  }

  public removeCondition(condition: UnlockCondition) {
    const index = this.selectedCategory.unlockCondition.indexOf(condition);
    this.selectedCategory.unlockCondition.splice(index, 1);
    this.buildUnlockConditionsTable(this.selectedCategory);
  }

  public getFilteredCategoriesList() {
    const unlockConditions = this.selectedCategory.unlockCondition.map((c) => c.name);

    this.filteredCategories = this.categories.filter(
      (c) => !includes(unlockConditions, c.name) && c !== this.selectedCategory,
    );
  }

  public imageIsPlaceHolder(img): boolean {
    return img.includes('Placeholder');
  }

  public isGameEnabled(gameName: string) {
    const game = this.gamesList[gameName];
    const categoryGameList = this.selectedCategory.games;

    return categoryGameList.find((g) => g.type === game.toLowerCase());
  }

  public addGameToList(gameName: string) {
    const game = this.gamesList[gameName];
    const categoryGameList = this.selectedCategory.games;
    const selectedGame = categoryGameList.find((g) => g.type === game.toLowerCase());

    if (selectedGame) {
      const index = categoryGameList.indexOf(selectedGame);
      categoryGameList.splice(index, 1);
    } else {
      const newGameEntry = { type: game.toLowerCase() };
      categoryGameList.push(newGameEntry);
    }
  }

  public addLogo(property: string) {
    const cloudinaryConfig = {
      cloud_name: environment.CLOUDINARY_CLOUD_NAME,
      upload_preset: environment.CLOUDINARY_UPLOAD_PRESET,
      secure: true,
    };

    cloudinary.openUploadWidget(cloudinaryConfig, (error, result) => {
      if (result) {
        const [imageResponse] = result;
        const { secure_url, url } = imageResponse;
        this.selectedCategory[property] = secure_url || url;
      } else if (error && error.message !== 'User closed widget') {
        this._snackBar.open('There was an error while uploading the image, please try again later', 'OK', {
          duration: 5000,
        });
      }
    });
  }

  public async updateCategory() {
    try {
      await this.rest.put('categories/' + this.selectedCategory.id, this.selectedCategory);

      this._snackBar.open('Category sucessfully updated', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } catch (error) {
      this._snackBar.open('Could not update this category', 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  public async deleteCategory() {
    this.confirm
      .createConfirmation('Warning', 'Are you sure you want to delete this category', 'Yes', 'No')
      .then(async () => {
        try {
          await this.rest.delete('categories/' + this.selectedCategory.id);

          this._snackBar.open('Category deleted!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.selectedCategory = undefined;
          this.reset.emit();
        } catch (error) {
          this._snackBar.open('Could not delete this category', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public openNewCategoryDialog() {
    const dialog = this.dialog.open(NewCategoryDialog, {
      width: '600px',
      height: '800px',
      panelClass: 'modal-border',
      data: {
        categoriesList: this.categories,
      },
    });

    dialog.afterClosed().subscribe(async (newCategory: Category) => {
      if (newCategory) {
        try {
          await this.rest.post('categories', newCategory);

          this._snackBar.open('New category created!', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.reset.emit();
        } catch (error) {
          this._snackBar.open('Could not create this category', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      }
    });
  }

  public openBonusPackDialog() {
    const dialog = this.dialog.open(BonusPackDialog, {
      width: '600px',
      height: '600px',
      panelClass: 'modal-border',
      data: {
        bonusPack: this.selectedCategory.bonusPack,
      },
    });

    dialog.afterClosed().subscribe((bonusPack) => {
      if (bonusPack) {
        this.selectedCategory.bonusPack = bonusPack;
      }
    });
  }

  public checkIfLanguageIsEnabled(languageCode: string) {
    if (!this.selectedCategory.avaliableLanguages) {
      const languageList = this.languages.map((l) => l.languageCode);
      this.selectedCategory.avaliableLanguages = languageList;
    }

    const lang = this.selectedCategory.avaliableLanguages.find((l) => l === languageCode);

    if (lang) {
      return true;
    }

    return false;
  }

  public addAvaliableLanguage(languageCode: string) {
    const avaliableLanguages = this.selectedCategory.avaliableLanguages;

    const lang = avaliableLanguages.find((l) => l === languageCode);

    if (!lang) {
      this.selectedCategory.avaliableLanguages.push(languageCode);
    } else {
      const selectedLang = this.selectedCategory.avaliableLanguages.find((l) => l === languageCode);
      const index = this.selectedCategory.avaliableLanguages.indexOf(selectedLang);
      this.selectedCategory.avaliableLanguages.splice(index, 1);
    }
  }
}
