import { Injectable } from '@angular/core';

import { Messages } from 'src/app/shared/interfaces/Message.interface';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { CoursesService } from 'src/app/pages/courses/services/courses/courses.service';
import { Course } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(private _rest: RestAPIService, private _courses: CoursesService) {}

  public rows: Messages[] = [];

  public async getMessages() {
    const messages = await this._rest.get('message/all');
    const courses = await this.getCourses();
    return (this.rows = this.formatedColumnsMessage(messages, courses));
  }

  public formatedColumnsMessage(messages: Messages[], courses?: Course[]) {
    return messages.map((message: Messages) => {
      const course = courses?.find((course: Course) => course.id === message.courseId);
      return {
        ...message,
        createdAt: new Date(message.createdAt).toLocaleDateString(),
        courseName: course ? course.title : 'System Message',
      };
    });
  }

  public async getCourses() {
    await this._courses.initCourses();
    const courseList = this._courses.getRawCourseData();
    return courseList;
  }
}
