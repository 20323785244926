import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';

@Component({
  selector: 'app-messages-table',
  templateUrl: './messages-table-dashboard.component.html',
  styleUrls: ['./messages-table-dashboard.component.scss'],
})
export class MessagesTableDashboardComponent implements OnInit {
  @Input() columns: string[];
  @Input() rows: any[];

  //Config
  public sortDirection = 'asc';
  sortField = null;

  constructor(
    private _rest: RestAPIService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    private confirm: ConfirmationService,
  ) {}

  async ngOnInit() {}

  getColumnValue(row: any, column: string): string {
    switch (column) {
      case 'Source':
        return row.courseName;
      case 'Message':
        return row.text;
      case 'Created Date':
        return row.createdAt;
      case 'Actions':
        return 'Actions';
      default:
        return '';
    }
  }

  public sortTable(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }

    this.rows.sort((a, b) => {
      if (a[this.sortField] < b[this.sortField]) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (a[this.sortField] > b[this.sortField]) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  public async deleteMessage(id: string) {
    const confirm = await this.confirm.createConfirmation('Neuralign', `Do you want to delete ${name}?`, 'Yes', 'No');
    if (!confirm) return;

    try {
      await this._rest.delete(`/message/id/${id}`, {});
      this.rows = this.rows.filter((row: any) => row.id !== id);
    } catch (error) {
      this._snackBar.open('Error deleting message', 'OK', {
        duration: 2000,
      });
    }
  }
}
