<div class="main">
  <section class="container">
    <div class="title">
      <h2>Message Registration</h2>
    </div>

    <form class="form">
      <div class="two-column-layout">
        <div class="left-column">
          <div class="input-group">
            <div class="input-block">
              <label for="messageText">Text *</label>
              <textarea
                type="text"
                [(ngModel)]="messageForm.text"
                name="messageText"
                placeholder="Insert the message Text"
              ></textarea>
            </div>
          </div>

          <div class="input-block-select">
            <label for="mediaType">Select the media</label>
            <select
              [(ngModel)]="messageForm.mediaType"
              name="mediaType"
            >
              <option value="image">Image</option>
              <option value="video">Video</option>
            </select>
          </div>

          <div
            *ngIf="messageForm.mediaType === 'image'"
            class="image"
          >
            <div
              *ngIf="messageForm.image"
              class="image-preview"
            >
              <h2 class="title">Image Preview</h2>
              <img
                [src]="messageForm.image"
                alt="Image preview"
                class="preview-media"
              />
            </div>
            <button
              type="button"
              class="btn-image"
              (click)="addMessageImage()"
            >
              Add message image
            </button>
          </div>

          <div
            *ngIf="messageForm.mediaType === 'video'"
            class="video"
          >
            <div
              *ngIf="safeMessageVideoUrl"
              class="video-preview"
            >
              <h2 class="title">Video Preview</h2>
              <iframe
                [src]="safeMessageVideoUrl"
                frameborder="0"
                allowfullscreen
                class="preview-media"
              ></iframe>
            </div>
            <input
              class="input-block"
              type="text"
              [(ngModel)]="messageForm.video"
              name="videoUrl"
              placeholder="Enter YouTube video URL"
              (ngModelChange)="updateSafeUrl()"
            />
          </div>
        </div>

        <div class="right-column">
          <div class="my-4">
            <div class="inputHeader text-center">Send to users type</div>
            <div class="d-flex justify-content-center">
              <div
                *ngFor="let userType of getUserTypes()"
                class="mx-2"
              >
                <mat-checkbox
                  (change)="addAllowedUser(userTypes[userType])"
                  [checked]="checkUserType(userTypes[userType])"
                >
                  {{ userTypes[userType] | titlecase }}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="my-4">
            <div class="inputHeader text-center">
              Send to allowed organizations
            </div>
            <div class="d-flex justify-content-center">
              <div
                *ngFor="let orgType of getAllowedOrgsTypes()"
                class="mx-2"
              >
                <mat-checkbox
                  (change)="addAllowedOrgs(allowedOrgsTypes[orgType])"
                  [checked]="isAllowedOrgs(allowedOrgsTypes[orgType])"
                >
                  {{ allowedOrgsTypes[orgType] | titlecase }}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div
            *ngIf="selectedHowToSend === 'manually'"
            class="allowed-users-list my-4"
          >
            <div class="inputHeader text-center">Selected Organizations</div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Organization Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let org of allowedOrgs">
                  <td>{{ org?.organization?.id }}</td>
                  <td>{{ org?.organization?.name | titlecase }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            *ngIf="usersRead?.length"
            class="allowed-users-list my-4"
          >
            <div class="inputHeader text-center">
              Users who read the message
            </div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Organization Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of usersRead">
                  <td>{{ user.organization.id }}</td>
                  <td>{{ user.organization.name | titlecase }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="button-group">
        <button
          type="button"
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>

<style>
  .preview-media {
    max-width: 450px;
    height: 350px;
  }
</style>
