import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  IconDefinition,
  faCircle,
  faCreditCard,
  faReceipt,
  faSpinner,
  faArrowsRotate,
  faRetweet,
  faXmark,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { Invoice, PaymentPlan, StripeEvent } from 'src/app/shared/interfaces/Stripe.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { AdjustPaymentSettingsComponent } from '../subscription-control-center/adjust-payment-settings-dialog/adjust-payment-settings-dialog';
import { SubscriptionPaymentsDialogComponent } from '../subscription-control-center/subscription-payments.dialog/subscription-payments-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { HttpParams } from '@angular/common/http';
import { get, upperFirst, parseInt } from 'lodash';
import { User } from 'src/app/shared/models';
import { CurrencyPipe } from '@angular/common';
import moment from 'moment';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenService } from 'src/app/services/token/token.service';
import { PaymentStatus, PaymentTypes, InvoiceStatus, PaymentPeriod } from './enums/installment-payments.enum';

@Component({
  selector: 'app-installment-payments-control-center',
  templateUrl: './installment-payments-control-center.html',
  styleUrls: ['./installment-payments-control-center.scss'],
  providers: [[CurrencyPipe]],
})
export class InstallmentPaymentsControlCenter implements OnInit, AfterViewInit {
  constructor(
    private rest: RestAPIService,
    private auth: AuthService,
    private _dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private confirm: ConfirmationService,
    private snackBar: MatSnackBar,
    private tokenService: TokenService,
  ) {}
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<PaymentPlan>;

  public b2cProviderColumns: string[] = [
    'client',
    'cost',
    'next charge',
    'end date',
    'completed payments',
    'payment interval',
    'status',
    'studentsIncluded',
    'actions',
  ];
  public b2cClientColumns: string[] = [
    'cost',
    'next charge',
    'end date',
    'completed payments',
    'status',
    'studentsIncluded',
    'card',
    'actions',
  ];

  dataSource: MatTableDataSource<PaymentPlan> = new MatTableDataSource([]);
  displayedColumns: string[] = [];

  public readonly visaIcon: IconDefinition = faCcVisa;
  public readonly masterIcon: IconDefinition = faCcMastercard;
  public readonly amexIcon: IconDefinition = faCcAmex;
  public readonly receipt: IconDefinition = faReceipt;
  public readonly circle: IconDefinition = faCircle;
  public readonly spinner: IconDefinition = faSpinner;
  public readonly card: IconDefinition = faCreditCard;
  public readonly refresh: IconDefinition = faArrowsRotate;
  public readonly renew: IconDefinition = faRetweet;
  public readonly cancel: IconDefinition = faXmark;
  public readonly reactivate: IconDefinition = faRotateRight;

  public cardBrands = [
    { brand: 'visa', icon: this.visaIcon },
    { brand: 'mastercard', icon: this.masterIcon },
    { brand: 'amex', icon: this.amexIcon },
  ];

  public paymentList = [];
  public pageLoading = true;
  public tableLoading = false;

  public searchSize = 10;
  public pageIndex = 0;
  public startingAfter = '';
  public nextPage = '';
  public listSize = 0;

  public isB2cClient = false;
  public isB2cProvider = false;

  public monthlyIncome = undefined;
  public incomeCurrency = 'CAD';

  async ngOnInit(): Promise<void> {
    await this.getB2CRecurringPayments();
    this.pageLoading = false;
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  async checkNewPayments(user: User) {
    try {
      const paymentsList = await this.rest.get('b2cProvider/' + user.id + '/getRecurringPayments');

      if (!paymentsList) {
        throw new Error('Failed to fetch recurring payments, please reload the page');
      }

      const data = paymentsList.paymentData || [];

      const paymentData = this.getB2cProviderPaymentData(data);

      const paymentDataIdList = paymentData.map((p: PaymentPlan) => p.id);
      const uniquePaymentDataList = this.paymentList.filter((p: PaymentPlan) => !paymentDataIdList.includes(p.id));
      this.paymentList.unshift(...uniquePaymentDataList);
      this.dataSource.data = this.paymentList;
      this.listSize = paymentsList.listSize;

      this.renderTable();

      this.tableLoading = false;
    } catch (error) {
      this.tableLoading = false;
    }
  }

  private renderTable() {
    if (this.table) {
      this.table.renderRows();
    }
  }

  async refreshTable() {
    this.tableLoading = true;

    const user: User = await this.auth.getUser();

    this.checkNewPayments(user).then(() => {
      this.paginator.length = this.listSize;
    });

    this.getTotalIncome(user.id);
  }

  async getB2CRecurringPayments(startingAfter = '', nextPage = '') {
    const user: User = await this.auth.getUser();

    this.isB2cClient = user.patron !== undefined;
    this.isB2cProvider = user.organization !== undefined;

    const paymentsList = user.patron
      ? await this.rest.get('b2c/' + user.id + '/getRecurringPayments', {
          params: new HttpParams().set('startingAfter', startingAfter).set('listSize', this.searchSize),
        })
      : await this.rest.get('b2cProvider/' + user.id + '/getRecurringPayments', {
          params: new HttpParams().set('page', nextPage).set('listSize', this.searchSize),
        });

    const data = paymentsList.paymentData || [];
    this.nextPage = get(paymentsList, 'nextPage', '');

    const paymentData = user.patron ? this.getB2cClientPaymentData(data) : this.getB2cProviderPaymentData(data);
    this.paymentList = this.paymentList.concat(paymentData);

    this.dataSource.data = this.paymentList;

    if (this.listSize === 0) {
      this.listSize = paymentsList.listSize;
    }

    this.tableLoading = false;

    if (this.isB2cProvider && !this.monthlyIncome) {
      this.incomeCurrency = user.organization.b2cCurrency;
      this.getTotalIncome(user.id);
    }
  }

  public getB2cProviderPaymentData(data): PaymentPlan[] {
    const paymentData = data.map((payment) => {
      return this.transformB2cProviderData(payment);
    });

    return paymentData;
  }

  public getB2cClientPaymentData(data): PaymentPlan[] {
    const paymentData = data.map((payment) => {
      return this.transformB2cClientData(payment);
    });

    return paymentData;
  }

  public transformB2cProviderData(payment) {
    return {
      cost: payment.plan.amount / 100,
      currency: payment.plan.currency.toUpperCase(),
      id: payment.id,
      subscriptionItemId: payment.items.data[0].id,
      currentPeriodEnd: payment.current_period_end,
      lastInvoice: payment.latest_invoice,
      user: payment.user,
      endDate: payment.payment.cancel_at,
      startDate: payment.start_date,
      type: get(payment, 'metadata.type', ''),
      isCanceled: payment.cancel_at_period_end,
      completedPayments: payment.completedPayments,
      interval: upperFirst(payment.plan.interval),
      studentsIncluded: payment.metadata.studentsIncluded ? payment.metadata.studentsIncluded : 1,
    };
  }

  public transformB2cClientData(payment) {
    return {
      cost: payment.plan.amount / 100,
      currency: payment.plan.currency.toUpperCase(),
      id: payment.id,
      subscriptionItemId: payment.items.data[0].id,
      currentPeriodEnd: payment.current_period_end,
      isCanceled: payment.cancel_at_period_end,
      card: payment.card,
      endDate: payment.cancel_at,
      lastInvoice: payment.latest_invoice,
      studentsIncluded: payment.metadata.studentsIncluded ? payment.metadata.studentsIncluded : 1,
      type: get(payment, 'metadata.type', ''),
      renewAmount: parseInt(get(payment, 'metadata.renewAmount', '0')),
      completedPayments: payment.completedPayments,
      interval: upperFirst(payment.plan.interval),
      startDate: payment.start_date,
    };
  }

  public onPageChange($event: StripeEvent) {
    if (this.paymentList.length < this.listSize && this.pageIndex < $event.pageIndex) {
      this.tableLoading = true;
      const startingAfter = $event.pageIndex > 0 ? this.paymentList[this.paymentList.length - 1].id : '';
      this.getB2CRecurringPayments(startingAfter, this.nextPage).then(() => {
        this.paginator.length = this.listSize;
      });
      this.pageIndex++;
    }
  }

  public returnPaymentSource(payment) {
    return payment.card.last4;
  }

  public invoiceIsPaid(invoice: Invoice): boolean {
    if (!invoice) {
      return false;
    }

    return invoice.paid;
  }

  public returnCardIcon(payment: PaymentPlan) {
    const brand = get(payment, 'card.brand', '');
    const cardBrand = this.cardBrands.find((b) => b.brand.toLowerCase() === brand.toLowerCase());

    return cardBrand ? cardBrand.icon : '---';
  }

  public getDate(date: string) {
    const correctDate = Number(date + '000');
    const dateFormat = new Date(correctDate);
    return ` ${dateFormat.getDate()} / ${dateFormat.getMonth() + 1} / ${dateFormat.getFullYear()} `;
  }

  public paymentsCompleted(payment: PaymentPlan): boolean {
    const completedPayments = payment.completedPayments;
    const totalPayments = this.getPaymentsAmount(payment);

    const cancelAtMoment = moment.unix(payment.endDate);
    const currentPeriodEndMoment = moment.unix(payment.currentPeriodEnd);
    const difference = Math.abs(cancelAtMoment.diff(currentPeriodEndMoment, 'hours'));

    return completedPayments === totalPayments || difference === 0;
  }

  public getPaymentClass(payment: PaymentPlan) {
    if (!payment.lastInvoice) {
      return PaymentStatus.Failed;
    }

    const invoice = payment.lastInvoice;

    // if the invoice is already marked as uncollectible return failed

    if (get(invoice, 'status_transitions.marked_uncollectible_at', '')) {
      return PaymentStatus.Failed;
    }

    switch (invoice.status) {
      case InvoiceStatus.Paid:
        return PaymentStatus.Paid;
      case InvoiceStatus.Draft || InvoiceStatus.Open:
        return PaymentStatus.Waiting;
      default:
        return PaymentStatus.Waiting;
    }
  }

  public adjustPaymentSettings(subscription: PaymentPlan) {
    const dialog = this._dialog.open(AdjustPaymentSettingsComponent, {
      width: '600px',
      data: subscription.id,
      panelClass: 'modal-border',
      disableClose: true,
    });

    dialog.afterClosed().subscribe(async (updatedSubscription) => {
      if (updatedSubscription) {
        const subscription = this.paymentList.find((s) => s.id === updatedSubscription.id);
        subscription.card = updatedSubscription.default_payment_method.card;

        if (updatedSubscription.latest_invoice) {
          subscription.lastInvoice = updatedSubscription.latest_invoice;
        }

        this.tableLoading = false;

        this.snackBar.open('Success! Your payment method is updated', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  public async findPaymentInvoices(subscription: PaymentPlan) {
    this.tableLoading = true;
    const user = await this.auth.getUser();
    const invoices = await this.rest.get('b2c/' + user.id + '/installmentPayment/' + subscription.id + '/getInvoices', {
      msg: 'Could not get this subscription invoices',
    });
    this.tableLoading = false;
    this._dialog.open(SubscriptionPaymentsDialogComponent, {
      data: invoices,
      panelClass: 'custom-modalbox',
      width: '435px',
    });
  }

  public async getTotalIncome(id: string) {
    const income = (await this.rest.get('/b2cProvider/' + id + '/recurringPayments/income')) || 0;

    this.monthlyIncome = this.currencyPipe.transform(income, this.incomeCurrency, 'symbol', '1.2-2');
  }

  public getInstallmentPaymentsAmount(subscription: PaymentPlan, interval: moment.unitOfTime.Diff) {
    const startDate = moment.unix(subscription.startDate);
    startDate.startOf('day');
    const endDate = moment.unix(subscription.endDate);
    endDate.startOf('day');
    const dateDiff = endDate.diff(startDate, interval);
    const numberOfPayments = dateDiff;

    return numberOfPayments;
  }

  public shouldAllowRetry(invoice) {
    return get(invoice, 'paid', false) === false && get(invoice, 'attempt_count', 0) > 0;
  }

  public async retryPayment(payment) {
    this.confirm.createConfirmation('Warning', 'Would you like to retry this payment?', 'Yes', 'No').then(async () => {
      try {
        this.tableLoading = true;

        const paymentConfirmation = await this.rest.put('/subscription/' + payment.id + '/retryPayment', {});

        if (paymentConfirmation) {
          this.snackBar.open(
            'Success! Your payment is confirmed and the credit will be available in up to an hour',
            'Close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            },
          );

          const subscription = paymentConfirmation.subscription;

          if (subscription) {
            const updatedPaymentData = this.transformB2cProviderData(subscription);

            const paymentData = this.paymentList.find((p) => p.id === subscription.id);

            if (paymentData) {
              const index = this.paymentList.indexOf(paymentData);
              this.paymentList.splice(index, 1, updatedPaymentData);
              this.dataSource.data = this.paymentList;
            }

            if (this.table) {
              this.table.renderRows();
            }

            if (this.tokenService.refreshCredits) {
              this.tokenService.refreshCredits.emit();
            }
          }

          this.tableLoading = false;
        } else {
          throw new Error('Payment retry failed');
        }
      } catch (error) {
        this.tableLoading = false;
      }
    });
  }

  public getPaymentsAmount(payment: PaymentPlan) {
    const interval: moment.unitOfTime.Diff = payment.interval as moment.unitOfTime.Diff;
    const totalPayments = this.getInstallmentPaymentsAmount(payment, interval);

    return totalPayments;
  }

  public allowRenew(payment: PaymentPlan) {
    const paymentType = get(payment, 'type', '');
    const completedPayments = payment.completedPayments;
    const totalPayments = this.getPaymentsAmount(payment);

    return completedPayments === totalPayments && paymentType === PaymentTypes.PROGRAM_RERUN;
  }

  public allowCancel(payment: PaymentPlan) {
    return get(payment, 'renewAmount', 0) > 0;
  }

  public async renewRecurringPayment(payment: PaymentPlan) {
    const period = payment.interval === PaymentPeriod.MONTH ? 12 : 1;

    this.confirm
      .createConfirmation(
        'Warning',
        'This will renew your payment plan for another year. Are you sure you want to proceed?',
        'Yes',
        'No',
      )
      .then(async () => {
        this.tableLoading = true;
        const updatedPayment = await this.rest.put(
          '/b2c/renewRecurringPayment/id/' + payment.id + '/period/' + period,
          {},
        );
        const paymentData = this.paymentList.find((p) => p.id === updatedPayment.id);

        if (paymentData) {
          paymentData.endDate = updatedPayment.cancel_at;
          paymentData.renewAmount = parseInt(get(payment, 'metadata.renewAmount', '0')) + 1;
        }

        if (this.table) {
          this.table.renderRows();
        }

        this.tableLoading = false;

        this.snackBar.open('Success! Your payment was renewed!', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  public async changePaymentStatus(payment: PaymentPlan, isCancel: boolean) {
    const reactivateText = 'This will reactivate your payment plan. Are you sure you want to proceed?';
    const cancelText =
      'This will cancel your payment plan before your next charge. After that the student will no longer be able to access Neuralign. This action cannot be undone. Are you sure you want to proceed?';

    this.confirm.createConfirmation('Warning', isCancel ? cancelText : reactivateText, 'Yes', 'No').then(async () => {
      this.tableLoading = true;
      const updatedPayment = await this.rest.put(
        '/b2c/recurringPayment/id/' + payment.id + '/paymentStatus/' + isCancel,
        {},
      );
      const paymentData = this.paymentList.find((p) => p.id === updatedPayment.id);

      if (paymentData) {
        paymentData.isCanceled = isCancel;
        paymentData.endDate = updatedPayment.current_period_end;
      }

      if (this.table) {
        this.table.renderRows();
      }

      this.tableLoading = false;

      this.snackBar.open(`Success! Your payment was ${isCancel ? 'canceled' : 'reactivated'}!`, 'Close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }
}
