import { Component, OnInit } from '@angular/core';

import { MessagesService } from './messages.service';
import { Messages } from 'src/app/shared/interfaces/Message.interface';

@Component({
  selector: 'app-message-dashboard',
  templateUrl: './messages-dashboard.component.html',
  styleUrls: ['./messages-dashboard.component.scss'],
})
export class MessagesDashboardComponent implements OnInit {
  public rows: Messages[];
  public filteredRows: Messages[];

  constructor(private messages: MessagesService) {}

  async ngOnInit() {
    this.rows = await this.messages.getMessages();
    this.filteredRows = this.rows;
  }

  public filterRows(rows: Messages[]) {
    this.filteredRows = rows;
  }

  public newMessage(message: Messages) {
    const formattedMessage = this.messages.formatedColumnsMessage([message]);
    this.filteredRows = [formattedMessage[0], ...this.filteredRows];
  }
}
