import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService } from 'src/app/services/auth/auth.service';
import { Messages } from '../../interfaces/Message.interface';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { CoursesService } from 'src/app/pages/courses/services/courses/courses.service';
import { ProgressService } from 'src/app/pages/courses/services/progress/progress.service';
import { ProgressManagerHelper } from 'src/app/shared/helpers/progress-manager';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './message.dialog.html',
  styleUrls: ['./message.dialog.scss'],
})
export class MessageDialogComponent {
  messages = [];
  readMessages = [];
  safeMessageVideoUrl: SafeResourceUrl;
  private _progressManager: ProgressManagerHelper;
  private userId: string;

  public currentSlide = 0;

  constructor(
    private _router: Router,
    private _auth: AuthService,
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private _rest: RestAPIService,
    public _courses: CoursesService,
    private _progress: ProgressService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: Messages[],
  ) {
    this._progressManager = new ProgressManagerHelper(_rest);

    if (data.length > 0) {
      data.map((message) => {
        this.messages.push(message);
      });
      this.currentSlide = 0;
    }
  }

  async ngOnInit() {
    const user = await this._auth.getUser();
    this.userId = user.id;
  }

  public async close() {
    this.dialogRef.close();
    this.checksIfMessageWasRead();
    await this.markReadMessage();
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.checksIfMessageWasRead();
    this.currentSlide = previous < 0 ? this.messages.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.checksIfMessageWasRead();
    this.currentSlide = next === this.messages.length ? 0 : next;
  }

  public async goToCourse(message: Messages) {
    await this._courses.initCourses();
    const courseList = this._courses.getRawCourseData();
    const courseProgresses = this._courses.getRawProgressData();

    const course = courseList.find((c) => c.id === message.courseId);
    const progress = courseProgresses.find((p) => p.courseId === message.courseId);

    if (progress === undefined) {
      const progress = await this._rest.post(
        'courseProgress/accountId/' + this.userId,
        this._progressManager.createNewProgress(course.id, course.lessons),
      );

      this._progress.loadProgresses([progress]);
    } else {
      await this._progressManager.updateUserProgress(progress, course);
    }

    this.checksIfMessageWasRead();
    await this.markReadMessage();
    this.dialogRef.close();

    this._router.navigate(['courses', 'learning', message.courseId]);
    this.captureClarityEvent();
  }

  public async checksIfMessageWasRead() {
    const { id } = this.messages[this.currentSlide];
    if (id && !this.readMessages.includes(id)) {
      this.readMessages.push(id);
    }
  }

  public async markReadMessage() {
    if (this.readMessages.length > 0) {
      this.readMessages.map(async (messageId) => {
        await this._rest.post('message/user-read', {
          userId: this.userId,
          messageId: messageId,
        });
      });
    }
  }

  updateSafeUrl(video: string) {
    const videoId = this.extractVideoId(video);
    if (videoId) {
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    } else {
      this.safeMessageVideoUrl = null;
    }
  }

  private extractVideoId(url: string): string | null {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  private captureClarityEvent() {
    (window as any)?.clarity?.('event', 'go_to_course_btn_clicked');
  }
}
