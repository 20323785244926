import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest/rest-api.service';
import { PricingBundle } from 'src/app/pages/pricing/interfaces/pricing-bundle.interface';
import {
  Category,
  GlobalConfiguration,
  Language,
} from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigurationHelper {
  constructor(private rest: RestAPIService, private auth: AuthService) {}

  private categories: Category[];
  private bundles: PricingBundle[];
  private firstPurchaseBundles: PricingBundle[];
  private globalConfiguration: GlobalConfiguration;
  private languages: Language[];

  public setGlobalConfiguration(globalConfiguration: GlobalConfiguration): void {
    if (globalConfiguration === undefined) {
      // This method could be called after the front page is returned a 401 Unauthorized response from calling the API for the global config, so guard against that
      return; // no op
    } else {
      // load global config
      this.globalConfiguration = globalConfiguration;
      this.categories = globalConfiguration.categories;
      this.bundles = globalConfiguration.bundles.filter((b) => !b.isFirstPurchaseBundle);
      this.firstPurchaseBundles = globalConfiguration.bundles.filter((b) => b.isFirstPurchaseBundle);
    }
  }

  public async getGlobalConfiguration(): Promise<GlobalConfiguration> {
    if (!this.globalConfiguration) {
      const globalConfig = await this.rest.get('globalConfig/config');
      this.setGlobalConfiguration(globalConfig);
    }

    return this.globalConfiguration;
  }

  public async getCategories(forceReload = false): Promise<Category[]> {
    if (!this.categories || forceReload) {
      this.categories = await this.rest.get('categories');
    }

    return this.categories;
  }

  public async getProgramBundles(): Promise<PricingBundle[]> {
    const user = await this.auth.getUser();
    const isFirstPurchaseDone = user.firstTokenPurchased;

    if (!this.bundles || !this.firstPurchaseBundles) {
      const bundles = await this.rest.get('globalConfig/bundles/' + user.tokenId);
      this.bundles = bundles.filter((b: PricingBundle) => !b.isFirstPurchaseBundle);
      this.firstPurchaseBundles = bundles.filter((b: PricingBundle) => b.isFirstPurchaseBundle);
    }

    return !isFirstPurchaseDone ? this.firstPurchaseBundles : this.bundles;
  }

  public async getLanguages(): Promise<Language[]> {
    if (!this.languages) {
      this.languages = await this.rest.get('languages');
    }

    return this.languages;
  }
}
