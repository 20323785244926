import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LayoutsModule } from 'src/app/shared/layouts/layouts.module';
import { RestAPIService } from '../../services/rest/rest-api.service';
import { MaterialComponentsModule } from 'src/app/shared/modules/material-components.module';
import { MessagesDashboardComponent } from './messages-dashboard.component';
import { MessagesTableDashboardComponent } from './messages-table-dashboard/messages-table-dashboard.component';
import { MessagesTableButtonModule } from './messages-table-button/messages-table-button.module';
import { MessagesHeaderDashboardComponent } from './messages-header-dashboard/messages-header-dashboard.component';
import { MessagesHeaderDashboardButtonComponent } from './messages-header-dashboard/messages-header-dashboard-button/messages-header-dashboard-button.component';
import { MessagesModalComponent } from './messages-modal/messages-modal.component';

@NgModule({
  declarations: [
    MessagesDashboardComponent,
    MessagesTableDashboardComponent,
    MessagesHeaderDashboardComponent,
    MessagesHeaderDashboardButtonComponent,
    MessagesModalComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MaterialComponentsModule,
    LayoutsModule,
    FormsModule,
    MessagesTableButtonModule,
    CommonModule,
    FontAwesomeModule,
  ],
  providers: [RestAPIService],
})
export class MessagesDashboardModule {}
