import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { CoursesService } from 'src/app/pages/courses/services/courses/courses.service';
import { MessageDialogComponent } from 'src/app/shared/dialogs/message/message.dialog';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(
    public _auth: AuthService,
    private _dialog: MatDialog,
    public _rest: RestAPIService,
    private _logger: LoggerService,
    public _courses: CoursesService,
  ) {}

  public async initUserMessage() {
    try {
      const user = await this._auth.getUser();
      const isSessionActive = this._auth.checkIsSessionActive();
      const messages = await this._rest.get(`message/login/${user.id}`);
      const filteredMessages = [];

      if (messages?.length > 0) {
        await this._courses.initCourses();
        const courseList = this._courses.getRawCourseData();
        messages.filter((message) => {
          if (message?.courseId) {
            const course = courseList.find((c) => c.id === message.courseId);
            if (course && message.createdAt > user.createdAt) {
              filteredMessages.push(message);
            }
          } else {
            if (message.createdAt > user.createdAt) {
              filteredMessages.push(message);
            }
          }
        });
      }

      if (filteredMessages?.length > 0 && isSessionActive) {
        this._dialog.open(MessageDialogComponent, {
          data: filteredMessages,
          panelClass: 'modal-border',
          width: '800px',
        });
      }
    } catch (error) {
      this._logger.error(error);
    }
  }
}
